<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card v-if="!loading">
          <v-card-title>Physical Mail Campaign Templates</v-card-title>
          <v-card-subtitle>This template will be used when creating a new physical mail campaign to prefill most fields automatically.</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col>
                Available variables: [skill] [from_name] [from_title]
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    v-model="template.title"
                    label="Title"
                    counter="60"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    v-model="template.customaftertitle"
                    label="Text After Title"
                    counter="150"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                    v-model="template.body"
                    label="Body"
                    counter="800"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    v-model="template.customfooter"
                    label="Footer"
                    counter="250"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn @click="putData" color="primary">Update</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-text>
            <v-skeleton-loader type="card-avatar, article, actions"></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "PhysicalMailTemplate",

  mounted() {
    this.getTemplate()
  },

  data() {
    return {
      template: {
        title: null,
        body: null,
        customaftertitle: null,
        customfooter: null,
      },
      loading: true,
    }
  },

  methods: {
    getTemplate() {
      let self = this

      axios.get(`${this.$store.state.apiUrl}/api/physical-mail-template/1`, this.$store.state.headers)
          .then(function (response) {
            self.template = response.data
            self.loading = false
          });
    },

    putData() {
      let self = this

      axios.get(`${this.$store.state.apiUrl}/sanctum/csrf-cookie`).then(response => {
        console.log(response)
        axios.put(`${this.$store.state.apiUrl}/api/physical-mail-template/1`, self.template, this.$store.state.headers)
            .then(function (response) {
              console.log(response.data);
              alert("updated successfully")
            })
            .catch(function (response) {
              alert(response.response.data.errors.messages)
            })
      });
    },
  }
}
</script>

<style scoped>

</style>
